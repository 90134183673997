import "./AuthInput.css";
import { createRef, useEffect, useRef, useState } from 'react';

function AuthInput({
  onChange,
  name,
  type = "text",
  inputMode = "text",
  placeholder = "",
  value,
  error,
  icon,
}) {
  const [focused, setFocused] = useState(false)
  const ref = createRef(null);


  const handleMouseOut = (currentRef) => {
    if (!value) {
      setFocused(false)
    }
  };

  const handleMouseOn = (currentRef) => {
    if (document.activeElement === currentRef) {
      setFocused(true)
    }
  };

  return (
    <div
      className={`auth-input`}
    >
      {/* {icon({
        mainClassName: "auth-input__icon",
        fillClassName: "auth-input__icon-fill",
      })} */}
      <input
        ref={ref}
        onClick={() => setFocused(true)}
        onMouseEnter={() => setFocused(true)}
        onMouseLeave={handleMouseOut}
        // onFocus={() => handleMouseOn(ref.current)}
        // onBlur={() => handleMouseOut(ref.current)}
        className="auth-input__input"
        name={name}
        id={name}
        type={type}
        inputMode={inputMode}
        // placeholder={placeholder}
        value={value || ""}
        onChange={onChange}
      />
      <label className={`${focused || value !== '' ? 'label_focus' : ''} label`}>{placeholder}</label>
    </div>
  );
}

export default AuthInput;
