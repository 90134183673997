import { useEffect, useState } from 'react';
import { Coin } from '../../../assets/icons/icons';
import '../MenuCard/MenuCard.css';
import { motion } from 'framer-motion';
import noPhoto from '../../../assets/images/NoPhoto.png';

function MenuCard({ item, i, preloader, setCurrentId, setOpen, isOpen }) {
    const [load, setLoad] = useState(false)
    const curLevel = item?.current_level

    function handleClick(id) {
        setOpen(!isOpen)
        setCurrentId(id)
    }

    return (
        <div className='menu-card' onClick={() => handleClick(item.id)}>
            {preloader ?
                <div className='menu-card_animated-gradient'></div> :
                <>
                    <p className='menu-card__level'>{item.current_level} LVL</p>
                    <div className='menu-card__img-box'>
                        <motion.div
                            className={`animated-gradient ${load ? 'animated-gradient_under' : ''}`}>
                        </motion.div>
                        {item.image ?
                            <motion.img
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1 }}
                                alt=''
                                onLoad={() => setLoad(true)}
                                className='menu-card__img'
                                src={item.image}>
                            </motion.img>
                            :
                            <motion.img
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1 }}
                                alt=''
                                className='menu-card__img'
                                onLoad={() => setLoad(true)}
                                src={noPhoto}>
                            </motion.img>
                        }
                    </div>
                </>
            }

            {preloader ?
                <div className='menu-card__text_animated-gradient'></div> :
                <>
                    <p className='menu-card__title'>{item.title}</p>
                    <p className='menu-card__parameters'>{item?.measure}</p>
                    <div className='menu-card__info'>

                        <div className='menu-card__info__line'>
                            <div
                                style={{
                                    width: `${item?.amount >= item?.levels[item?.levels.length - 1]?.needed ? 100 : (item?.amount - item?.levels[curLevel]?.needed) * (100 / (item?.levels[curLevel + 1]?.needed - item?.levels[curLevel]?.needed))}%`,
                                }}
                                className='loading__progress'
                            ></div>
                        </div>

                        <div className='menu-card__info-number'>
                            {item?.amount >= item?.levels[item?.levels.length - 1]?.needed ?
                                <div className='menu-card__info-number__current-level'>MAX
                                </div>
                                :
                                <div className='menu-card__info-number__current-level'>{item?.amount - item?.levels[curLevel]?.needed}
                                    <span className='menu-card__info-number__max-level'>/{item?.levels[curLevel + 1]?.needed - item?.levels[curLevel]?.needed}</span>
                                </div>
                            }

                            <div className='menu-card__info-number__farm'>
                                Прибыль в час:
                                <p className='menu-card__info-number__farm__price'>
                                    {Intl.NumberFormat('en-US', {
                                        notation: "compact",
                                        maximumFractionDigits: 2
                                    }).format(Number(item?.levels[curLevel]?.income_an_hour))}
                                </p>
                                <Coin mainClassName={'main-task__price__icon'} />
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default MenuCard;