import avatar1 from '../images/avatar1.webp'
import avatar2 from '../images/avatar2.webp'
import avatar3 from '../images/avatar3.webp'
import avatar4 from '../images/avatar4.webp'
import avatar5 from '../images/avatar5.webp'
import avatar6 from '../images/avatar6.webp'
import avatar7 from '../images/avatar7.webp'
import avatar8 from '../images/avatar8.webp'

export const AVATARS = [
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8
]
