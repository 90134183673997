import mainApi from '../../assets/api/MainApi';
import '../PhonePopup/PhonePopup.css';
import TemplatePopup from '../TemplatePopup/TemplatePopup';
import { motion } from 'framer-motion'

function PhonePopup({ isOpen, setOpen, text, setCard }) {
    const tg = window.Telegram?.WebApp


    function fetchCard() {
        mainApi
            .getCard()
            .then((res) => {
                setCard(res);  // Successfully received card
       
            })
            .catch((err) => {
                console.log('Error fetching card, retrying...', err);
                setTimeout(fetchCard, 5000);  // Retry after 10 seconds on failure
            });
    }

    function handleClick() {
        // Request contact details
        window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft');
        tg?.requestContact((success) => {
            if (success) {
                // Close the popup after the phone number is shared
                fetchCard();
                setOpen(false);
                // Start fetching the card
            } else {
                console.log('Phone number not shared');
            }
        });
    }

    return (
        <>
            {isOpen ?
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='phone-popup__bg'></motion.div>
                : null}
            <TemplatePopup secondClassName={'phone-popup'} isOpen={isOpen} setOpen={setOpen}>
                <svg className='phone-popup__icon' xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none">
                    <rect width="68" height="68" rx="34" fill="#D6A65D" />
                    <path d="M28.469 39.5309C26.259 37.3209 24.5929 34.8464 23.486 32.336C23.2537 31.8053 23.3897 31.1838 23.7996 30.7739L25.3466 29.2269C26.6141 27.9594 26.6141 26.1668 25.5072 25.0599L23.2915 22.8442C21.8162 21.369 19.4249 21.369 17.9496 22.8442L16.718 24.0739C15.3184 25.4736 14.7347 27.4929 15.1125 29.4951C16.0456 34.4308 18.913 39.835 23.5389 44.461C28.1649 49.087 33.5691 51.9543 38.5048 52.8875C40.5071 53.2652 42.5263 52.6816 43.926 51.2819L45.1557 50.0522C46.6309 48.5769 46.6309 46.1856 45.1557 44.7103L42.94 42.4946C41.8331 41.3877 40.0386 41.3877 38.9336 42.4946L37.2279 44.2022C36.818 44.6121 36.1966 44.75 35.6658 44.5158C33.1554 43.407 30.679 41.7391 28.469 39.5309Z" stroke="white" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M48.9995 30.334V19.0005H37.666" stroke="white" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M37.666 30.334L48.9995 19.0005" stroke="white" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" />
                    <circle cx="34" cy="34" r="30" stroke="#FFDB95" />
                </svg>
                <p className='phone-popup__text'>{text}</p>
                <button className='phone-popup__btn' onClick={() => handleClick()}>Поделится номером</button>
            </TemplatePopup>
        </>
    );
}

export default PhonePopup;