import './IncomePopup.css';
import { motion } from "framer-motion"
import { Coin } from '../../assets/icons/icons';
import { useEffect, useState } from 'react';

function IncomePopup({ income, setIncome, personalBonus, setPersonalBonus }) {
    const variants = {
        open: {
            opacity: 1,
            transition: { duration: 0.1 }
        },
        closed: {
            opacity: 0,
            transition: { duration: 0.1 },
        }
    };

    const [isOpen, setOpen] = useState(false)

    useEffect(() => {
        if (income > 0) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [income])

    useEffect(() => {
        if (personalBonus) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [personalBonus])

    function handleClick() {
        window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft')
        setOpen(false)
        setPersonalBonus(false)
    }

    return (
        <motion.div
            initial={false}
            animate={isOpen ? "open" : "closed"}
            variants={variants}
            className={`${isOpen ? 'income-popup_visible' : 'income-popup_unvisible'} income-popup`}>
            <Coin mainClassName={'income-popup__coin'} />
            <svg className='income-popup__coin-ligthing' xmlns="http://www.w3.org/2000/svg" width="349" height="349" viewBox="0 0 349 349" fill="none">
                <g filter="url(#filter0_f_36_2568)">
                    <circle cx="174.5" cy="174.5" r="70.5" fill="#FFB31F" />
                </g>
                <defs>
                    <filter id="filter0_f_36_2568" x="0" y="0" width="349" height="349" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="52" result="effect1_foregroundBlur_36_2568" />
                    </filter>
                </defs>
            </svg>
            <p className='income-popup__text'>Вы заработали</p>
            {personalBonus ?
                <p className='income-popup__number'>1500</p>
                :
                <p className='income-popup__number'>
                    {Intl.NumberFormat('ru-RU').format(income)}
                </p>
            }
            <button onClick={() => handleClick()} className='income-popup__btn'>ОК</button>
        </motion.div>
    );
}

export default IncomePopup