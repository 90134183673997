import '../ProfilePopup/ProfilePopup.css';
import TemplatePopup from '../../TemplatePopup/TemplatePopup';
import { motion } from 'framer-motion'

function ProfilePopup({ isOpen, setOpen, data, currentId }) {
    const curCoupon = data.find(item => item?.coupon?.id === currentId)

    return (
        <>
            {isOpen ?
                <motion.div
                    onClick={() => setOpen(!isOpen)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className='popup__bg' />
                : null}
            <TemplatePopup secondClassName={'profile-popup'} isOpen={isOpen} setOpen={setOpen}>
                <img src={curCoupon?.coupon?.image} alt='' className='profile-popup__img'></img>
                <p className='profile-popup__text'>{curCoupon?.coupon?.title}</p>
                <p className='profile-popup__desc'>{curCoupon?.coupon?.description}</p>
                <p className='profile-popup__code'>{curCoupon?.code}</p>
            </TemplatePopup>
        </>
    );
}

export default ProfilePopup;