import '../LevelUpPopup/LevelUpPopup.css';
import TemplatePopup from '../TemplatePopup/TemplatePopup';
import { LevelUp } from '../../assets/icons/icons';

function LevelUpPopup({ isOpenLevelUp, setOpenLevelUp }) {

    function handleClick() {
        window.Telegram?.WebApp.HapticFeedback.impactOccurred('soft')
        setOpenLevelUp(!isOpenLevelUp)
    }

    return (
        <TemplatePopup secondClassName={'popup-level-up'} isOpen={isOpenLevelUp} setOpen={setOpenLevelUp}>
            <LevelUp />
            <p className='popup-level-up__text'> Level Up!</p>
            <button onClick={() => handleClick()} className='popup-level-up__btn'>Закрыть</button>
        </TemplatePopup>
    );
}


export default LevelUpPopup;