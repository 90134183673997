import '../MainTask/MainTask.css';
import { Coin } from '../../../assets/icons/icons';
import moment from "moment-timezone";
import { useEffect, useState } from 'react';
import noPhoto from '../../../assets/images/NoPhoto.png'

function MainTask({ item, setCurrentId, currentId, setOpen, isOpen, preloader }) {

    function handleClick(id) {
        if (preloader) {
            return
        }
        else {
            setCurrentId(id)
            setOpen(!isOpen)
        }
    }

    const [now, setNow] = useState(moment())
    useEffect(() => {
        setTimeout(() => {
            setNow(moment())
        }, 1000);
    }, [now])

    const local_date = moment.utc(item.expires).local().format('YYYY-MM-DD HH:mm:ss');

    return (
        <div onClick={() => handleClick(item.id)} className={`main-task ${preloader ? 'main-task_animated-gradient' : ''}`}>
            <div className='main-task__timer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                    <path d="M2.20455 4.57955H4C4.10354 4.57955 4.19066 4.54419 4.26136 4.47348C4.33207 4.40278 4.36742 4.31566 4.36742 4.21212V1.85606C4.36742 1.75505 4.33207 1.66919 4.26136 1.59848C4.19066 1.52778 4.10354 1.49242 4 1.49242C3.89646 1.49242 3.80934 1.52778 3.73864 1.59848C3.66793 1.66919 3.63258 1.75505 3.63258 1.85606V3.8447H2.20455C2.10101 3.8447 2.01389 3.88005 1.94318 3.95076C1.87247 4.02146 1.83712 4.10859 1.83712 4.21212C1.83712 4.31566 1.87247 4.40278 1.94318 4.47348C2.01389 4.54419 2.10101 4.57955 2.20455 4.57955ZM4 8C3.45202 8 2.93687 7.8952 2.45455 7.68561C1.97222 7.47854 1.54672 7.19066 1.17803 6.82197C0.809343 6.45581 0.520202 6.03157 0.310606 5.54924C0.103535 5.06692 0 4.55051 0 4C0 3.45202 0.103535 2.93687 0.310606 2.45455C0.520202 1.97222 0.808081 1.54672 1.17424 1.17803C1.54293 0.809343 1.96843 0.521465 2.45076 0.314394C2.93561 0.104798 3.45202 0 4 0C4.54798 0 5.06313 0.104798 5.54545 0.314394C6.0303 0.521465 6.45581 0.809343 6.82197 1.17803C7.19066 1.54672 7.4798 1.97222 7.68939 2.45455C7.89899 2.93687 8.00379 3.45202 8.00379 4C8.00379 4.55051 7.89899 5.06692 7.68939 5.54924C7.4798 6.03157 7.19066 6.45581 6.82197 6.82197C6.45581 7.19066 6.03157 7.47854 5.54924 7.68561C5.06692 7.8952 4.55051 8 4 8Z" fill="#666682" />
                </svg>
                {moment(local_date).diff(now, 'hours') > 0 ? `${moment(local_date).diff(now, 'hours')}:` : null}
                {moment(moment(local_date).diff(now, 'mm:ss')).format('mm:ss')}
            </div>
            {item.image ?
                <img alt='' className='main-task__img' src={item.image}></img>
                :
                <img
                    alt=''
                    className='main-task__img'
                    src={noPhoto}>
                </img>
            }
            <div className='main-task__value'>
                {item.title}
            </div>
            <div className='main-task__use-box'>
                <div className='main-task__price'>
                    {new Intl.NumberFormat('ru-Ru').format(
                        Number(item.price),
                    )}
                    <Coin mainClassName={'main-task__price__icon'} />
                </div>
                <p className='main-task__use'>
                    {new Intl.NumberFormat('ru-Ru').format(
                        Number(item.left_count),
                    )}
                </p>
            </div>
        </div>
    );
}

export default MainTask;