import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './CustomInput.css';
import moment from 'moment';
// import { ErrorIcon } from '../../assets/icons/icons';

function CustomInput({
  type = 'text',
  inputMode = 'text',
  onChange,
  ...props
}) {
  const [isLabelTransform, setLabelTransform] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    setLabelTransform(Boolean(props.value));
    setTouched(Boolean(props.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFocused() {
    setIsFocused(true);
    setLabelTransform(true);
  }

  function handleBlur(evt) {
    const value = evt.target.value;
    setLabelTransform(Boolean(value));
    setIsFocused(false);
    setTouched(true);
    if (props.onBlur) props.onBlur();
  }

  return (
    <div className="custom-input">
      <div className="custom-input__input-box">
        <label
          className={`custom-input__label ${isLabelTransform || Boolean(props.value)
            ? 'custom-input__label_focused'
            : ''
            }`}
          htmlFor={props.name}
        >
          {props.label}
        </label>

        <input
          className={`custom-input__input ${isFocused ? 'custom-input__input_focused' : ''
            } ${touched && Boolean(props.error)
              ? 'custom-input__input_type_error'
              : ''
            }`}
          id={props.name}
          name={props.name}
          type={type}
          inputMode={inputMode}
          value={props.value}
          required={props.isRequired}
          readOnly={props.readOnly}
          max={props.max ? props.max : undefined}
          onChange={onChange}
          onClick={!props.readOnly ? handleFocused : undefined}
          onFocus={!props.readOnly ? handleFocused : undefined}
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (props.onKeyDown) {
              props.onKeyDown(e);
            }
          }}
        />
        <div
          style={{
            textAlign: 'left',
            width: '85%',
            position:'absolute'
          }}
          className='custom-input__input_left'
        >
          {props.value ? moment(props.value).format('DD.MM.YYYY') : ''}
        </div>

        {props.icon
          ? props.icon({
            mainClassName: 'custom-input__input-icon',
            strokeClassName: 'custom-input__input-icon-stroke',
            fillClassName: 'custom-input__input-icon-fill',
          })
          : null}
      </div>

      {/* <motion.div
        className="custom-input__error-box"
        initial={{
          height: '0px',
          marginTop: '0px',
          opacity: 0,
          visibility: 'hidden',
        }}
        animate={{
          height: touched && props.error ? 'fit-content' : '0px',
          marginTop: touched && props.error ? '6px' : '0px',
          opacity: touched && props.error ? 1 : 0,
          visibility: touched && props.error ? 'visible' : 'hidden',
        }}
        transition={{ duration: 0.2 }}
      >
        {touched && props.error ? (
          <p className="custom-input__error">
            <ErrorIcon
              mainClassName="custom-input__error-icon"
              fillClassName="custom-input__error-icon-fill"
            />
            {props.error}
          </p>
        ) : null}
      </motion.div> */}
    </div>
  );
}

export default CustomInput;
