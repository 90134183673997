import { MAIN_URL } from "../utils/constants";

class MainApi {
    // constructor({ baseUrl }) {
    //     MAIN_URL = baseUrl;
    // }

    _checkResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            const statusCode = res.status;
            return res.json().then((res) => {
                return Promise.reject({
                    statusCode: statusCode,
                    message: res.msg,
                    detail: res.detail,
                });
            });
        }
    }

    _checkResponseWithCookies({ res, func, params }) {
        if (res.ok) {
            return res.json();
        } else {
            const statusCode = res.status;
            return res.json().then((res) => {
                if (statusCode === 401 && res.detail === 'Signature has expired') {
                    console.log("ss");
                    return mainApi
                        .refreshJWT()
                        .then((res) => {
                            return func(params);
                        })
                        .catch((err) => {
                            return Promise.reject(err);
                        });
                } else {
                    return Promise.reject({
                        statusCode: statusCode,
                        message: res.msg,
                        detail: res.detail,
                    });
                }
            });
        }
    }

    refreshJWT() {
        return fetch(`${MAIN_URL}/auth/refresh-jwt`, {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }).then(this._checkResponse);
    }

    authLogin({
        initData,
        refId
    }) {
        return fetch(`${MAIN_URL}/auth/login`, {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                init_data: initData,
                referer_id: refId
            }),
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.authLogin,
            params: { initData, refId },
        }));
    }

    editProfile({
        username,
        first_name,
        last_name,
        date_of_birth,
        email,
        gender
    }) {
        return fetch(`${MAIN_URL}/auth/edit-profile`, {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: username,
                first_name: first_name,
                last_name: last_name,
                date_of_birth: date_of_birth,
                email: email,
                gender: gender
            }),
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.editProfile,
            params: { username, first_name, last_name, date_of_birth, email },
        }));
    }

    setRegistered() {
        return fetch(`${MAIN_URL}/auth/set-user-registered`, {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.setRegistered
        }));
    }

    getMe() {
        return fetch(`${MAIN_URL}/auth/get-me`, {
            method: "GET",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
        ).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getMe,
            params: {}
        }));
    }

    getRating({ limit, last_id }) {
        let params = {};
        if (last_id) params.last_id = last_id;
        if (limit) params.limit = limit;
        return fetch(
            `${MAIN_URL}/rating/get-all?` +
            new URLSearchParams(params),
            {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        ).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getRating,
            params: { limit },
        }));
    }

    getFriends({ limit, last_id }) {
        let params = {};
        if (last_id) params.last_id = last_id;
        if (limit) params.limit = limit;
        return fetch(
            `${MAIN_URL}/friends/get-all?` +
            new URLSearchParams(params),
            {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        ).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getFriends
        }));
    }

    inviteFriend({
        id
    }) {
        return fetch(`${MAIN_URL}/friends/invite`, {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: String(id)
            }),
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.inviteFriend,
            params: { id },
        }));
    }

    getAwards({ limit, last_id }) {
        let params = {};
        if (last_id) params.last_id = last_id;
        if (limit) params.limit = limit;
        return fetch(
            `${MAIN_URL}/awards/get-all?` +
            new URLSearchParams(params),
            {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        ).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getAwards,
        }));
    }

    buyAward({ id }) {
        return fetch(`${MAIN_URL}/awards/buy`, {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                award_id: id
            }),
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.buyAward,
            params: { id },
        }));
    }

    getOwnArchivAwards({ limit, last_id }) {
        let params = {};
        if (last_id) params.last_id = last_id;
        if (limit) params.limit = limit;

        return fetch(
            `${MAIN_URL}/awards/get-all-owned?is_used=true&` +
            new URLSearchParams(params),
            {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        ).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getOwnAwards,
            params: { limit },
        }));
    }

    getOwnAwards({ limit, last_id }) {
        let params = {};
        if (last_id) params.last_id = last_id;
        if (limit) params.limit = limit;

        return fetch(
            `${MAIN_URL}/awards/get-all-owned?is_used=false&` +
            new URLSearchParams(params),
            {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        ).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getOwnAwards,
            params: { limit },
        }));
    }

    sendTap({ count }) {
        return fetch(`${MAIN_URL}/tap/add`, {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                amount: count
            }),
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.sendTap,
            params: { count },
        }));
    }

    getBusiness() {
        return fetch(
            `${MAIN_URL}/business/get-all`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        ).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getBusiness,
        }));
    }

    buyBusiness({ id }) {
        return fetch(`${MAIN_URL}/business/buy`, {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                item_id: id
            }),
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.buyBusiness,
            params: { id },
        }));
    }

    getMenu() {
        return fetch(
            `${MAIN_URL}/menu/get-all`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        ).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getMenu,
        }));
    }

    getCard() {
        return fetch(
            `${MAIN_URL}/cards/get-card`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        ).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.getCard,
        }));
    }
}

const mainApi = new MainApi({
    baseUrl: MAIN_URL,
});

export default mainApi;