import mainApi from '../../../assets/api/MainApi';
import { Coin, CoinGrey } from '../../../assets/icons/icons';
import '../BusinessCard/BusinessCard.css';
import noPhoto from '../../../assets/images/NoPhoto.png'
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

function BusinessCard({ item, isOpen, setOpen, setCurrentId, setBusiness, preloader }) {
    const [load, setLoad] = useState(false)
    const curLevel = item?.current_level;

    function handleClick(id) {
        setOpen(!isOpen)
        setCurrentId(id)

        mainApi
            .getBusiness()
            .then((res) => {
                setBusiness(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                // setPreloader(false)
            })
    }

    return (
        <div className='business-card' onClick={() => handleClick(item.id)}>
            <div className='business-card__info'>
                {preloader ?
                    <div className='business-card__img business-card__img_animated-gradient'></div> :
                    <div className='business-card__img-box'>
                        <motion.div
                            className={`business-card__img_default-animated-gradient ${load ? 'business-card__img_default-animated-gradient-under' : ''}`}>
                        </motion.div>

                        {item?.image ?
                            <motion.img
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1 }}
                                alt=''
                                className='business-card__img'
                                onLoad={() => setLoad(true)}
                                src={item?.image}>
                            </motion.img>
                            :
                            <motion.img
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1 }}
                                alt=''
                                className='business-card__img'
                                onLoad={() => setLoad(true)}
                                src={noPhoto}>
                            </motion.img>
                        }
                    </div>
                }

                {preloader ?
                    <div className='business-card__info_animated-gradient'></div>
                    :
                    <div>
                        <p className='business-card__info__title'>{item?.name}</p>
                        <p className='business-card__info__farm'>Прибыль в час:</p>
                        <p className={`business-card__info__farm business-card__info__farm__number ${curLevel !== 0 ? 'business-card__info__farm__number_white' : ''}`}>
                            <CoinGrey strokeClassName={`${curLevel !== 0 ? 'business-card__info__farm__coin_white' : ''}`} />
                            {curLevel !== 0 ? '' : '+'}
                            {Intl.NumberFormat('en-US', {
                                notation: "compact",
                                maximumFractionDigits: 2
                            }).format(curLevel === 0 ?
                                Number(item?.levels[curLevel]?.income_an_hour)
                                : Number(item?.levels[curLevel - 1]?.income_an_hour)
                            )}
                        </p>
                    </div>
                }
            </div>
            {
                preloader ?
                    <div className='business-card__price-box_animated-gradient'></div>
                    :
                    <div className='business-card__price-box'>
                        <div className='business-card__price-box__line'></div>
                        <p className={`business-card__price-box__level ${curLevel !== 0 ? 'business-card__price-box__level_white' : ''}`}>
                            lvl {curLevel}
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="26" viewBox="0 0 2 26" fill="none">
                            <path d="M1 26L0.999999 -8.64267e-07" stroke="#3E3E55" />
                        </svg>
                        <p className='business-card__price-box__coin'>
                            {curLevel !== item?.levels.length ?
                                <>
                                    <Coin mainClassName={'business-card__price-box__icon'} />
                                    {Intl.NumberFormat('en-US', {
                                        notation: "compact",
                                        maximumFractionDigits: 2
                                    }).format(Number(item?.levels[curLevel]?.price))}
                                </>
                                : 'max lvl'
                            }
                        </p>
                    </div>
            }
        </div >
    );
}

export default BusinessCard;