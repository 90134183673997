import '../Raiting/Raiting.css';
import { motion } from 'framer-motion'
import RaitingCard from './RaitingCard/RaitingCard';
import { Coin } from '../../assets/icons/icons';
import { AVATARS } from '../../assets/utils/utils';
import { RATING_CARDS, maxNumberForNoUsernameHash, minNumberForNoUsernameHash, niknames } from '../../assets/utils/constants';
import TemplatePopup from '../TemplatePopup/TemplatePopup';
import { createRef, useEffect, useState } from 'react';
import mainApi from '../../assets/api/MainApi';

function Raiting({ isOpenRating, setOpenRating, user, total, }) {
    const [rating, setRaiting] = useState([])
    const tg = window.Telegram?.WebApp
    const PERSON_RAITING_LIMIT = 50

    useEffect(() => {
        if (!user) return
        mainApi
            .getRating({ limit: PERSON_RAITING_LIMIT })
            .then((res) => {

                setRaiting(res.data);
                setRaiting((prev) => (
                    prev.map(item => {
                        const randomIndex = Math.floor(Math.random() * (niknames.length - 1));
                        const randomNumber = Math.floor(Math.random() * (maxNumberForNoUsernameHash - minNumberForNoUsernameHash + 1) + minNumberForNoUsernameHash);

                        if (item.user_data?.username) return item
                        if (item?.id === user?.id) return {
                            ...item,
                            user_data: {
                                username: user?.user_data?.username
                            }
                        }
                        else return {
                            ...item,
                            user_data: {
                                username: `${niknames[randomIndex]}#${randomNumber}`
                            }
                        }
                    })))
                // setRaiting(RATING_CARDS)
                console.log(res.data);
                console.log(rating);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                // setPreloaders(false)
            })
    }, [user])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            mainApi
                .getRating({ limit: PERSON_RAITING_LIMIT })
                .then((res) => {
                    if (JSON.stringify(rating) !== JSON.stringify(res.data)) {
                        setRaiting(res.data);
                    }
                    console.log(res.data);
                })
                .catch((err) => {
                    console.log(err);
                })
        }, 900000)
        return () => {
            clearTimeout(delayDebounceFn);
        }
    }, [rating])

    useEffect(() => {
        setRaiting((prevState) => prevState.map(item => {
            if (item.id !== user.id) return item
            else { return { ...item, balance_data: { ...item.balance_data, total_income: Number(user.balance_data.total_income) + total.click } } }
        }))
        console.log(rating)
    }, [total.click])

    const formatRating = () => {
        const curRating = rating?.findIndex((item) => item.id === user.id) !== -1 ? rating?.findIndex((item) => item.id === user.id) + 1 : user?.balance_data?.rating
        return curRating < 10 ? `0${curRating}` : curRating?.toString();
    };

    return (
        <TemplatePopup secondClassName={'rating-box'} isOpen={isOpenRating} setOpen={setOpenRating}>
            <motion.div
                // initial={{ opacity: 0 }}
                // animate={{ opacity: 1 }}
                // transition={{ duration: 0.5 }}
                className="rating">
                <p className='rating__title'>
                    РЕЙТИНГ
                </p>
                <div className='rating__header-table'>
                    <p className='rating__header-table__text'>место</p>
                    <p className='rating__header-table__text'>имя</p>
                    <p className='rating__header-table__text'>посещений Союза</p>
                    <p className='rating__header-table__text'>монеты</p>
                </div>
                <div className='rating__cards-grid'>
                    {rating?.sort(function (a, b) {
                        return Number(b?.balance_data?.total_income) - Number(a?.balance_data?.total_income)
                    }).map((item, i) => {
                        return (
                            <RaitingCard key={i} i={i} item={item} />
                        )
                    })}
                </div>
                <div className='rating-card-box'>
                    <div className='rating__line'></div>
                    <div className='rating-card rating-card_your'>
                        <p className='rating-card__number'>{formatRating()}</p>
                        <img alt='' className='rating-card__img' src={AVATARS[user?.balance_data?.level - 1]}></img>
                        <p className='rating-card__name rating-card__name_yellow'>{user?.user_data?.username}</p>
                        <p className='rating-card__visits'>{user?.balance_data?.union_visits}</p>
                        <p className='rating-card__coin'>
                            {Intl.NumberFormat('en-US', {
                                notation: "compact",
                                maximumFractionDigits: 2
                            }).format(Number(user?.balance_data?.total_income) + Number(total?.click))}
                            <Coin mainClassName={'rating-card__icon'} />
                        </p>
                    </div>
                </div>
            </motion.div>
        </TemplatePopup>
    );
}

export default Raiting;