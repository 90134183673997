import './ForComputer.css';
import pc from '../../assets/images/forPc.png';

function ForComputer() {

    return (
        <div className='for-pc'>
            <img src={pc} alt='' className='for-pc__bg'></img>
            <p className='for-pc__text'>
                Пожалуйста, откройте
                на мобильном устройстве
            </p>
            <svg className='for-pc__phone' xmlns="http://www.w3.org/2000/svg" width="44" height="70" viewBox="0 0 44 70" fill="none">
                <rect x="4" y="4" width="36" height="62" rx="6" fill="#1E1E2D" fill-opacity="0.65" stroke="#FFB31F" stroke-width="2" />
                <path d="M18 60H26" stroke="#FFB31F" stroke-width="2" stroke-linecap="round" />
                <circle cx="22" cy="9.5" r="1.5" fill="#FFB31F" />
            </svg>
            <div>
                <p className='for-pc__title'>Union</p>
                <p className='for-pc__title for-pc__title_second'>Coin</p>

                <div className='for-pc__terms'>
                    Developed by Caba Hub
                </div>
            </div>
        </div>
    );
}

export default ForComputer