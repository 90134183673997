import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import './SubcategoryNav.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import { useEffect, useState } from 'react';

const SlideContent = ({ currentCategoryName, title, productsByCat, isLast }) => {
  const id = title.toLowerCase().replaceAll(' ', '-');

  return (
    <div
      className={`subcat-nav__slide ${isLast ? 'subcat-nav__slide_size_wide' : ''
        }`}
    >
      <a
        className={`subcat-nav__link ${currentCategoryName === id ? 'subcat-nav__link_active' : ''
          }`}
        href={`#${id}`}
      >
        <div
          className={`subcat-nav__mark-box ${currentCategoryName === id ? 'subcat-nav__mark-box_visible' : ''
            }`}
        >
          <div className="subcat-nav__link-mark" />
        </div>
        <p className="subcat-nav__link-text">{title}</p>
      </a>
    </div>
  );
};

function SubcategoryNav({ menu, products, setSwiper, currentCategoryName }) {
  const [blockScroll, setBlockScroll] = useState(false)

  useEffect(() => {
    if (blockScroll) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "initial"
    }
    console.log(blockScroll)
  }, [blockScroll]);

  return (
    <div
      onTouchMoveCapture={() => setBlockScroll(true)}
      onTouchStartCapture={() => setBlockScroll(true)}
      onTouchEndCapture={() => setBlockScroll(false)}
      className="subcat-nav">
      <Swiper
        className="subcat-nav__swiper"
        slidesPerView="auto"
        spaceBetween={3}
        freeMode={{ enabled: true, sticky: true }}
        modules={[FreeMode]}
        onInit={(evt) => setSwiper(evt)}
      >
        {menu?.map((item, i) => (
          <SwiperSlide key={item.id}>
            <SlideContent
              isLast={i === menu.length - 1}
              title={item.title}
              productsByCat={products
              }
              {...{ currentCategoryName }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default SubcategoryNav;
